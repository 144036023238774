
import { defineComponent, PropType } from 'vue'
import { GChart } from 'vue-google-charts'

export default defineComponent({
  components: { GChart },
  props: {
    sdgCounts: Array as PropType<any[]>,
    sdgColor: Array as PropType<any[]>,
  },
  data() {
    return {
      primaryColors: [],
    }
  },
  computed: {
    dataSeries() {
      const sdgArray = this.sdgCounts.map((sdg) => ({
        x: '',
        y: sdg.positivePrimary + sdg.positiveSecondary,
        primary: sdg.positivePrimary,
        secondary: sdg.positiveSecondary,
        sdgNumber: sdg.number,
        sdgName: sdg.name,
      }))

      return [
        {
          name: 'SDG',
          data: sdgArray,
        },
      ]
    },
    xAxisCategories() {
      return this.sdgCounts.map((sdg) => `SDG - ${sdg.number}`)
    },
    options() {
      return {
        chart: {
          type: 'bar',
          stacked: false,
          toolbar: { show: false },
          id: 'vuechart-example',
        },
        dataLabels: {
          enabled: true,
        },
        grid: { show: false },
        legend: { show: false },
        xaxis: {
          tickAmount: 2,
          tooltip: { enabled: false },
          labels: { show: false },
          axisTicks: { show: false },
          categories: this.xAxisCategories,
        },
        yaxis: {
          tooltip: { enabled: false },
          axisTicks: { show: false },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 10,
            distributed: true,
          },
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex]
            return (
              '<ul>' +
              '<li>' +
              `<portfolio-screening-sdgimpact-sdg :sdg-number="${data.sdgNumber}" :sdg="${data.sdgName}" size="35" />` +
              '</li>' +
              '<li><b>Total</b>: ' +
              data.y +
              '</li>' +
              '<li><b>Positive Primary</b>: ' +
              data.primary +
              '</li>' +
              '<li><b>Negative Primary</b>: ' +
              data.secondary +
              '</li>' +
              '</ul>'
            )
          },
        },
      }
    },
  },
})
